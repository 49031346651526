<template>
  <el-container class="shopList ">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>登记管理</el-breadcrumb-item>
        <el-breadcrumb-item>完善登记</el-breadcrumb-item>
        <el-breadcrumb-item v-if="filing_state==1">新增登记</el-breadcrumb-item>
        <el-breadcrumb-item v-if="filing_state==2">编辑登记</el-breadcrumb-item>
        <el-breadcrumb-item v-if="filing_state==3||filing_state==4">登记详情</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt15">
<!--      <img class="type_img" v-if="filing_state==2" src="../../../../assets/image/shenhe.png">-->
<!--      <img class="type_img" v-if="filing_state==3" src="../../../../assets/image/tongguo.png">-->
<!--      <img class="type_img" v-if="filing_state==4" src="../../../../assets/image/bohui.png">-->
      <el-form :inline="true" status-icon ref="form4" :model="form4" class="demo-ruleForm">
        <el-collapse v-model="activeNames" @change="handleChange1" >
      <el-collapse-item title="企业资证上传" name="1">
        <el-row class="box mt20  f14">
           <el-col :span="7" class="tl">
            <el-form-item size="medium" label="营业执照：" class="w" label-width="41%" prop="accessory_list[0].accessory_url" :rules="[{required: true, message: '请上传营业执照', trigger: 'blur' }]">
              <div class="recordadd-upload">
                <el-image v-if="filing_state==3&&businessLicense!=''" style="width: 160px;height: 160px"
                  :src="businessLicense"
                  :preview-src-list="[form4.accessory_list[0].accessory_url]" alt=""></el-image>
                  <img v-else-if="filing_state==3&&form4.accessory_list[0].accessory_url==''" style="width: 160px;height: 160px" src="../../../../assets/image/moren.png"  />
                <el-upload v-else-if="filing_state!=3&&imageUrl=='1234567'" ref="upload" multiple type="file" :file-list="fileList" action="/pc/file/upload"
                  list-type="picture-card" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                  :on-success="handlepoll" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                  :before-remove="beforeRemove" :class="{disabled:uploadDisabled}">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-upload v-else-if="filing_state!=3&&imageUrl!='1234567'" ref="upload" multiple type="file" :file-list="fileList" action=""
                  list-type="picture-card" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                   :http-request="(params) =>{return handlepoll(params)}"  :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                  :before-remove="beforeRemove" :class="{disabled:uploadDisabled}">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
                <div class="tc"></div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="7" class="tl">
            <el-form-item size="medium" :label="form4.company_tag_id==2?'备案证：':'食品经营许可证：'" class="w" label-width="41%">
              <div class="recordadd-upload">
                <el-image v-if="filing_state==3&&license!=''" style="width: 160px;height: 160px"
                  :src="license"
                  :preview-src-list="[form4.accessory_list[1].accessory_url]" alt=""></el-image>
                  <img v-else-if="filing_state==3&&form4.accessory_list[1].accessory_url==''" src="../../../../assets/image/moren.png" style="width: 160px;height: 160px"/>
                <el-upload v-else-if="filing_state!=3&&imageUrl=='1234567'" ref="upload" multiple action="/pc/file/upload" list-type="picture-card"
                  :file-list="fileList1" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                  :on-success="handlepoll1" :on-preview="handlePictureCardPreview" :on-remove="handleRemove1"
                  :before-remove="beforeRemove" :class="{disabled:uploadDisabled1}">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-upload v-else-if="filing_state!=3&&imageUrl!='1234567'" ref="upload" multiple action="" list-type="picture-card"
                  :file-list="fileList1" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                  :http-request="(params) =>{return handlepoll(params)}" :on-preview="handlePictureCardPreview" :on-remove="handleRemove1"
                  :before-remove="beforeRemove" :class="{disabled:uploadDisabled1}">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>

                <div class="tc "></div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item title="企业基本信息" name="2">
         <el-row class="box mt20  f14">
            <el-col :span="11" class="tl">
            <el-form-item size="medium" label="企业名称：" class="w" label-width="28%">
              <el-input disabled placeholder="企业名称" maxlength='30' clearable v-model="company_name"
                class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="监管类型：" size="medium" class="w" label-width="28%" prop="company_type_id"
              :rules="[{required: true, message: '请输入监管类型', trigger: 'blur' }]">
              <el-select  v-model="form4.company_type_id" filterable>
                <el-option v-for="item in typetOptions" :key="item.company_type_id" :label="item.company_type_name"
                  :value="String(item.company_type_id)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item size="medium" label="主体类型：" class="w" label-width="28%" prop="company_tag_id"
              :rules="[{required: true, message: '请输入主体类型', trigger: 'blur' }]">
              <el-select  v-model="form4.company_tag_id" filterable>
                <el-option v-for="(item,index) in tagOptions" :key="index" :label="item.company_tag_name"
                  :value="String(item.company_tag_id)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="统一社会信用代码：" size="medium" prop="credit_code" :rules="[{required: true, message: '请输入统一社会信用代码', trigger: 'blur' }]">
              <el-input placeholder="请输入统一社会信用代码" disabled clearable
                v-model="credit_code" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col v-if="form4.company_tag_id==2" :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="备案证号：" size="medium" prop="record_code"
              :rules="[{required: true, message: '请输入备案证号', trigger: 'blur' }]">
              <el-input placeholder="备案证号" :disabled="filing_state==3||filing_state==4?true:false" clearable
                v-model="form4.record_code" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-else :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="食品经营许可证号：" size="medium" prop="record_code"
             >
             <!-- :rules="[{required: true, message: '请输入食品经营许可证号', trigger: 'blur' }]"-->
              <el-input placeholder="食品经营许可证号" :disabled="filing_state==3||filing_state==4?true:false" clearable
                v-model="form4.record_code" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==5" :span="11" class="tl">
            <el-form-item label="企业类型：" size="medium" class="w" label-width="28%">
              <el-input disabled clearable v-model="company_type" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==2" :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="备案时间：" size="medium">
              <el-date-picker type="date" :disabled="filing_state==3||filing_state==4?true:false" clearable
                placeholder="选择日期" :picker-options='pickerOptionsEnd' v-model="form4.record_time"
                format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="logTimeChange">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col v-if="form4.company_tag_id==2" :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="发证日期：" size="medium">
              <el-date-picker type="date" :disabled="filing_state==3||filing_state==4?true:false" clearable
                placeholder="选择发证日期" :picker-options='pickerOptionsEnd' v-model="form4.issue_time" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd" @change="logTimeChange"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==2" :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="有效期：" size="medium" >
              <el-date-picker type="date" :disabled="filing_state==3||filing_state==4?true:false" clearable
                placeholder="选择有效期" v-model="form4.unuseful_time" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                :picker-options="pickerOptions" @change="logTimeChange"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="注册地址：" size="medium" >
              <el-input placeholder="请输入注册地址" :disabled="filing_state==3||filing_state==4?true:false"
                v-model="form4.produce_address" clearable class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==5" :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="发证机关：" size="medium" prop="issue_unit">
              <el-input placeholder="请输入发证机关" :disabled="filing_state==3||filing_state==4?true:false"
                v-model="form4.issue_unit" clearable class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==5" :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="签发日期：" size="medium" >
              <el-date-picker type="date" :disabled="filing_state==3||filing_state==4?true:false" clearable
                placeholder="选择签发日期" v-model="form4.issue_time" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                @change="logTimeChange"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="经营场所面积：" size="medium" >
              <el-input style="width:100px" :disabled="filing_state==3||filing_state==4?true:false" placeholder="例：20"
                min="1" clearable v-model="form4.place_area" class="input-with-select mr10">
              </el-input>平方米（㎡）
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="从业人数：" size="medium" >
              <el-input placeholder="例：20" :disabled="filing_state==3||filing_state==4?true:false"
                v-model="form4.employ_num" clearable class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==5" :span="22" class="tl">
            <el-form-item class="w w24" label-width="14%" label="经营范围：" size="medium" prop="business_names_list"
              :rules="[{required: true,message: '请选择经营范围',trigger: ['blur','change'] }]">
              <el-checkbox-group v-model="form4.business_names_list"
                @change="handleChecked(3,form4.business_names_list)">
                <el-checkbox-button class="mr10" :disabled="filing_state==3||filing_state==4?true:false"
                  v-for="options in Options3" :label="options.dictionary_field" :key="options.dictionary_field">
                  {{options.dictionary_field}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <!-- <el-col v-if="form4.company_tag_id==2" :span="22" class="tl">
            <el-form-item class="w w24" label-width="14%" label="主营项目：" size="medium" prop="main_subject_names_list"
              :rules="[{required: true,message: '请选择主营项目',trigger: ['blur','change'] }]">
              <el-checkbox-group v-model="form4.main_subject_names_list"
                @change="handleChecked(1,form4.main_subject_names_list)">
                <el-checkbox-button class="mr10" :disabled="filing_state==3||filing_state==4?true:false"
                  v-for="options in Options1" :label="options.dictionary_field" :key="options.dictionary_field">
                  {{options.dictionary_field}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==2" :span="22" class="tl">
            <el-form-item class="w w24" label-width="14%" label="兼营项目：" size="medium">
              <el-checkbox-group v-model="form4.sub_subject_names_list"
                @change="handleChecked(2,form4.sub_subject_names_list)">
                <el-checkbox-button class="mr10" :disabled="filing_state==3||filing_state==4?true:false"
                  v-for="options in Options2" :label="options.dictionary_field" :key="options.dictionary_field">
                  {{options.dictionary_field}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-col> -->
          <el-col :span="22" class="tl">
            <el-form-item class="w w24" label-width="14%" label="经营储存条件：" size="medium" prop="storage_conditions_list"
              :rules="[{required: true,message: '请选择经营范围',trigger: ['blur','change'] }]">
              <el-checkbox-group v-model="form4.storage_conditions_list"
                @change="handleChecked(4,form4.storage_conditions_list)">
                <el-checkbox-button class="mr10" :disabled="filing_state==3||filing_state==4?true:false"
                  v-for="options in Options4" :label="options.dictionary_field" :key="options.dictionary_field">
                  {{options.dictionary_field}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="22" class="tl">
            <el-form-item class="w w24" label-width="14%" label="经营商品类型：" size="medium" prop="commodity_type_list"
              :rules="[{required: true,message: '请选择经营范围',trigger: ['blur','change'] }]">
              <el-checkbox-group v-model="form4.commodity_type_list"
                @change="handleChecked(5,form4.commodity_type_list)">
                <el-checkbox-button class="mr10" :disabled="filing_state==3||filing_state==4?true:false"
                  v-for="options in Options5" :label="options.dictionary_field" :key="options.dictionary_field">
                  {{options.dictionary_field}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item size="medium" label="所属市场：" class="w" label-width="28%" >
              <el-select v-model="form4.market_company_id" filterable>
                <el-option v-for="(item,index) in markets" :key="index" :label="item.market_company_name"
                  :value="String(item.market_company_id)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item title="法人身份证附件" name="3">
        <el-row class="box mt20  f14">
          <el-col :span="7" class="tl">
            <el-form-item size="medium" label="身份证(头像面)" class="w" label-width="41%" prop="accessory_list[2].accessory_url" :rules="[{required: true, message: '请上传身份证', trigger: 'blur' }]">
              <div class="recordadd-upload">
                <el-image v-if="filing_state==3&&form4.accessory_list[2].accessory_url!=''" style="width: 160px;height: 160px"
                  :src="id_card"
                  :preview-src-list="[form4.accessory_list[2].accessory_url]" alt=""></el-image>
                  <img v-else-if="filing_state==3&&form4.accessory_list[2].accessory_url==''" src="../../../../assets/image/moren.png" style="width: 160px;height: 160px" />
                <el-upload v-else-if="filing_state!=3&&imageUrl=='1234567'" ref="upload" multiple action="/pc/file/upload" list-type="picture-card"
                  :file-list="fileList2" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                  :on-success="handlepoll2" :on-preview="handlePictureCardPreview" :on-remove="handleRemove2"
                  :before-remove="beforeRemove" :class="{disabled:uploadDisabled2}">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-upload v-else-if="filing_state!=3&&imageUrl!='1234567'" ref="upload" multiple action="" list-type="picture-card"
                  :file-list="fileList2" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                  :http-request="(params) =>{return handlepoll2(params)}" :on-preview="handlePictureCardPreview" :on-remove="handleRemove2"
                  :before-remove="beforeRemove" :class="{disabled:uploadDisabled2}">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
                <div class="tc ">已开启OCR自动识别</div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="7" class="tl ">
            <el-form-item size="medium" label="身份证(国徽面)：" class="w" label-width="41%" prop="accessory_list[3].accessory_url" :rules="[{required: true, message: '请上传身份证国徽面', trigger: 'blur' }]">
              <div class="recordadd-upload">
                 <el-image v-if="filing_state==3&&form4.accessory_list[3].accessory_url!=''" style="width: 160px;height: 160px"
                  :src="id_card_side"
                  :preview-src-list="[form4.accessory_list[3].accessory_url]" alt=""></el-image>
                  <img v-else-if="filing_state==3&&form4.accessory_list[3].accessory_url==''" src="../../../../assets/image/moren.png" style="width: 160px;height: 160px"/>
                <el-upload v-else-if="filing_state!=3&&imageUrl=='1234567'" ref="upload" multiple action="/pc/file/upload" list-type="picture-card"
                  :file-list="fileList3" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                  :on-success="handlepoll3" :on-preview="handlePictureCardPreview" :on-remove="handleRemove3"
                  :before-remove="beforeRemove" :class="{disabled:uploadDisabled3}">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-upload v-else-if="filing_state!=3&&imageUrl!='1234567'" ref="upload" multiple action="" list-type="picture-card"
                  :file-list="fileList3" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                  :http-request="(params) =>{return handlepoll3(params)}" :on-preview="handlePictureCardPreview" :on-remove="handleRemove3"
                  :before-remove="beforeRemove" :class="{disabled:uploadDisabled3}">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
                <div class=" ">已开启OCR自动识别</div>
              </div>

            </el-form-item>
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item title="法人身份信息" name="4">
        <el-row class="box mt20  f14">
          <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" :label="form4.company_tag_id==2?'经营者姓名：':'法人姓名：'" size="medium" prop="credit_code"
              :rules="[{required: true, message: '请输入姓名', trigger: 'blur' }]">
              <el-input :placeholder="form4.company_tag_id==2?'请输入经营者姓名':'请输入法人姓名'"
                :disabled="filing_state==3||filing_state==4?true:false" maxlength="10" clearable
                v-model="form4.legal_person_name" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
        <el-form-item  label="性别：" size="medium" class="w" label-width="28%"  prop="user_sex" :rules="[{required: true,message: '请输入性别', trigger: ['blur','change'] }]">
           <el-select v-model="form4.user_sex" :disabled="filing_state==3||filing_state==4?true:false" clearable placeholder="性别" >
                <el-option v-for="(item,index) in optionsSex" :key="index" :label="item.label" :value="item.value" ></el-option>
              </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="民族：" size="medium" prop="user_nation">
              <el-input placeholder="请输入民族" :disabled="filing_state==3||filing_state==4?true:false" maxlength="10" clearable
                v-model="form4.user_nation" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="出生日期：" size="medium" prop="user_born">
              <el-input placeholder="请输入出生日期" :disabled="filing_state==3||filing_state==4?true:false" maxlength="10" clearable
                v-model="form4.user_born" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="详细地址：" class="w" label-width="28%">
              <el-input placeholder="如道路、门牌号、小区、楼栋号、单元室等（请勿重复录入省市区县信息）" :disabled="filing_state==3||filing_state==4?true:false" v-model="form4.user_address" size="medium" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="有效期：" class="w" label-width="28%">
              <el-input placeholder="请输入有效期" :disabled="filing_state==3||filing_state==4?true:false" v-model="form4.user_idcard_expirationtime" size="medium" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="签发机关：" class="w" label-width="28%">
              <el-input placeholder="请输入签发机关" :disabled="filing_state==3||filing_state==4?true:false" v-model="form4.user_idcard_government" size="medium" clearable></el-input>
            </el-form-item>
          </el-col>
           <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="手机号：" size="medium" prop="mobilephone"
            >
             <!-- :rules="[{required: true,message: '请输入手机号',trigger: 'blur'},{pattern: /^1[3|4|5|7|8|9][0-9]\d{8}$/,message: '手机号格式不对',trigger: 'blur'}]"-->
              <el-input placeholder="请输入11位手机号码" :disabled="filing_state==3||filing_state==4?true:false" clearable
                v-model="form4.mobilephone" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="身份证号：" size="medium" prop="idcard"
              :rules="[{required: true, message: '请输入身份证号', trigger: 'blur' },{ min: 15, max: 18, message: '请如实填写15或者18位号码，以供核对', trigger: 'blur' },{ pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,message: '请输入正确的身份证号码',trigger: 'blur'}]">
              <el-input placeholder="请输入18位身份证号码" :disabled="filing_state==3||filing_state==4?true:false" clearable
                v-model="form4.idcard" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-collapse-item>
       <el-collapse-item title="人员信息" name="5">
         <el-row class="box mt20  f14 people-message">
           <div class="box list">
            <el-form ref="form" :model="form" v-if="filing_state==1||filing_state==2">
              <el-table ref="table" :data="form.List" class="w" empty-text='暂无数据'>
                <el-table-column label="姓名">
                  <template slot-scope="scope">
                    <el-form-item size="medium" :prop="'List.' + scope.$index + '.name'"
                      :rules="[{required: true, message: '请输入姓名', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' }]">
                      <el-input placeholder="请输入姓名" clearable v-model="scope.row.name"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="联系电话">
                  <template slot-scope="scope">
                    <el-form-item size="medium" >
                      <el-input placeholder="请输入联系电话" clearable v-model="scope.row.mobilephone"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="健康证明" width="180">
                  <el-upload class="health_url" v-if="imageUrl=='1234567'" ref="upload" multiple fit="fill" style="width: 70px;height: 40px"
                    action="/pc/file/upload" :limit="1" list-type="picture-card" :before-upload="beforeAvatarUpload"
                    accept="image/png,image/jpg,image/jpeg" :on-success="handlepoll11"
                    :on-preview="handlePictureCardPreview" :on-remove="handleRemove11" :before-remove="beforeRemove"
                    :class="{disabled:uploadDisabled11}">
                    <el-button size="medium" type="primary">点击上传</el-button>
                  </el-upload>
                  <el-upload v-else class="health_url" ref="upload" multiple fit="fill" style="width: 70px;height: 40px"
                    action="" :limit="1" list-type="picture-card" :before-upload="beforeAvatarUpload"
                    accept="image/png,image/jpg,image/jpeg"  :http-request="(params) =>{return handlepoll11(params)}"
                    :on-preview="handlePictureCardPreview" :on-remove="handleRemove11" :before-remove="beforeRemove"
                    :class="{disabled:uploadDisabled11}">
                    <el-button size="medium" type="primary">点击上传</el-button>
                  </el-upload>
                </el-table-column>

                <el-table-column label="健康证有效期">
                  <template slot-scope="scope">
                    <el-form-item size="medium" :prop="'List.' + scope.$index + '.time'"
                      :rules="[{required: true, message: '选择有效期', trigger: 'blur' }]">
                      <el-date-picker type="date" clearable placeholder="选择有效期至" v-model="scope.row.time"
                        format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="pickerOptions"></el-date-picker>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="密切接触冷链食品" >
                 <template slot-scope="scope">
                  <el-switch active-text="是" inactive-text="否" active-value='1' inactive-value='2' v-model="scope.row.state" active-color="#409EFF" inactive-color="#ccc" > </el-switch>
               </template>
          </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="">
                    <el-button class="" size="medium" type="primary" @click="savepeople">保存</el-button>
                    <el-button class="back_btn" size="medium" plain @click="cancle">清除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
            <el-table :data="form4.company_employ_list" style="width: 100%" class="no-header">
              <el-table-column prop="company_employ_name"></el-table-column>
              <el-table-column prop="mobilephone"></el-table-column>
              <el-table-column width="180">
                <template slot-scope="scope">
                  <el-image v-if="scope.row.employ_health_url" :src="scope.row.employ_health_url" fit="fill"
                    :preview-src-list="[scope.row.employ_health_url]" style="width: 40px;height: 40px">
                  </el-image>
                  <img v-else src="../../../../assets/image/moren.png" style="width: 40px;height: 40px" />
                </template>
              </el-table-column>
              <el-table-column prop="employ_health_datedue"></el-table-column>
                <el-table-column prop="cold_chain_personnel">
               <template slot-scope="scope">
                 <span v-if="scope.row.cold_chain_personnel=='1'">是</span>
                 <span v-else-if="scope.row.cold_chain_personnel=='2'">否</span>
                </template>
             </el-table-column>
              <el-table-column fixed="right" v-if="filing_state==1||filing_state==2">
                <template slot-scope="scope">
                  <el-button class="back_btn" size="medium" plain @click="handleDelete(scope.$index, scope.row)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

          </div>
        </el-row>
      </el-collapse-item>
     </el-collapse>
        <el-col v-if="filing_state==2" :span="22" class="tl mt20">
          <el-button class="ml_5" size="medium" type="primary" @click="submitForm3('form4')">提交登记</el-button>
        </el-col>
        <el-col v-else-if="filing_state==1" :span="22" class="tl mt20">
          <el-button class="ml_5" size="medium" type="primary" @click="submitForm3('form4')">提交登记</el-button>
          <el-button class="ml_5" size="medium" type="primary" @click="save()">保存</el-button>
        </el-col>
        <!-- </el-row> -->
      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import Vue from 'vue'
import axios from '../../../../util/api'
import { local, aa, objKeySort } from '../../../../util/util'
import { imageUrl } from '../../../../config/index'
import { mapState } from 'vuex'
import { tengxuncloud } from '../../../../util/aliOss'
import md5 from 'js-md5';
export default {
  data () {
    return {
      imageUrl: imageUrl,
      morenUrl: require('../../../../assets/image/moren.png'),
      filing_state: '',
      businessLicense:'',
      license:'',
      id_card:'',
      id_card_side:'',
      activeNames: ['1','2','3','4','5'],
      optionsSex:[{label: '男',value:'1'},{label: '女',value:'2'}],
      company_name: '',
      credit_code:'',
      company_type: '',
      user: '',
      timeVisible: false,
      uploadDisabled: false,
      uploadDisabled1: false,
      uploadDisabled2: false,
      uploadDisabled3: false,
      uploadDisabled11: false,
      timer: '',
      loading: false,
      tableData: [],
      show: true,
      count: '',
      typetOptions: [],
      tagOptions: [],
      bizList: [],
      Options1: [],//主营项目
      Options2: [],//兼营项目
      Options3: [],//经营范围
      Options4: [],//储存条件
      Options5: [],//商品类型
      fileList: [],
      fileList1: [],
      fileList2: [],
      fileList3: [],
      provinceTemp: "",
      cityTemp: "",
      areaTemp: "",
      townTemp: "",
      villTemp: "",
      provinceOptions: [{region_name: "四川省", region_code: "C51"}],
      cityOptions: [],
      areaOptions: [],
      townOptions: [],
      villOptions: [],
      userData: {},
      form4: {
        company_id: '',
        company_name: '',
        company_type_id: '',
        company_tag_id: '',
        legal_person_name: '',
        market_company_id: '',
        market_company_name:'',
        markets:null,
        mobilephone: '',
        idcard: '',
        record_time: '',
        record_code: '',
        issue_time: '',
        unuseful_time: '',
        place_area: '',
        employ_num: '',
        credit_code: '',
        issue_unit: '',
        produce_address: '',
        produce_form_names_list: [],//生产形式
        produce_type_names_list: [],//生产类别
        main_subject_names_list: [],//主营项目
        sub_subject_names_list: [],//兼营项目
        storage_conditions_list: [],//储存条件
        commodity_type_list: [],//商品类型
        business_names_list: [],//经营范围
        accessory_list: [{ accessory_type: '营业执照', accessory_url: '' }, { accessory_type: '备案证', accessory_url: '' }, { accessory_type: '法人身份证', accessory_url: '' }, { accessory_type: '身份证国徽', accessory_url: '' }],
        company_employ_list: [],
        user_idcard_expirationtime:'',
        user_idcard_government:'',
        user_sex:'',
        user_nation:'',
        user_born:'',
        user_province:'',
        user_city:'',
        user_area:'',
        user_town:'',
        user_address:'',
      },
      form: { List: [{ name: '',mobilephone:'', healthimg: '', time: '',state:'' }] },
      dialogImageUrl: '',
      dialogVisible: false,
      pickerOptionsEnd: {
        disabledDate: time => {
          return time.getTime() > Date.now()
        }
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now();
        },
      },
    }
  },
  created () {
    var that = this;
    that.user = local.get('user');
    this.form4.company_id = String(local.get('company_id'));
    that.getData({ company_id: this.form4.company_id })
    that.getbizList()
    this.userData.user_type='1';
    this.handleUserLevel(this.userData,that.form4);
    this.getListOfMarkets();
  },
  methods: {
    getListOfMarkets(){
      axios.get("/pc/company-third-party-biz/markets").then(res=>{
        res = res.data;
        if(res){
          this.markets = res.markets;
        }
      })
    },
    handleChange1 (val) {
    },
    getData (params) {
      var that = this;
      axios.get('/pc/company/single', params).then((v) => {
        that.filing_state = v.data.company.filing_state;
        that.form4.company_type_id = String(v.data.company.company_type_id);
        that.form4.company_tag_id = String(v.data.company.company_tag_id);
        that.form4.company_id = String(v.data.company.company_id);
        that.credit_code= v.data.company.credit_code;
        that.company_type = v.data.company.company_type;
        that.company_name = v.data.company.company_name;
        this.getType()
        this.getTag(that.form4.company_type_id);
        this.getDictionary(that.form4.company_tag_id)
        if (local.get('companyDetail') && that.form4.company_type_id === local.get('companyDetail').company_type_id) {
          this.form4 = local.get('companyDetail')
          this.form4.accessory_list.forEach((item, index) => {
            if (item.accessory_url != "") {
                switch (item.accessory_type) {
                    case "营业执照": this.fileList = [{ name: item.accessory_type, url: item.accessory_url ? (item.accessory_url) : this.morenUrl }]; this.uploadDisabled = true;break;
                    case "食品经营许可证":this.fileList1 = [{ name: item.accessory_type, url: item.accessory_url ? (item.accessory_url) : this.morenUrl }]; this.uploadDisabled1 = true;break;
                    case "法人身份证正面":this.fileList2 = [{ name: item.accessory_type, url: item.accessory_url ? (item.accessory_url) : this.morenUrl }]; this.uploadDisabled2 = true;break;
                    case "法人身份证背面":this.fileList3 = [{ name: item.accessory_type, url: item.accessory_url ? (item.accessory_url) : this.morenUrl }]; this.uploadDisabled3 = true;break;
                }
            }
          })
        }
        if (that.filing_state !=1) {
          this.getcompanyDetail(params)
        }
      })
    },
    getcompanyDetail (params) {
      var that = this;
      axios.get('/pc/company-circulate/single', params).then((v) => {
        if (v.data.companyCirculate) {
          that.form4 = v.data.companyCirculate;
          that.form4.company_type_id = String(v.data.companyCirculate.company_type_id);
          that.form4.company_tag_id = String(v.data.companyCirculate.company_tag_id);
          that.form4.company_id = String(v.data.companyCirculate.company_id);
          that.form4.market_company = String(v.data.companyCirculate.market_company);
           that.form4.credit_code= that.credit_code;
          let aaa = v.data.companyCirculate.company_employ_list;
          let bbb = v.data.companyCirculate.accessory_list;
          that.form4.company_employ_list = [];
          that.form4.accessory_list = [];
          aaa.forEach(item => {
            that.form4.company_employ_list.push(objKeySort({
              employ_health_datedue: item.employ_health_datedue,
              employ_health_url: item.employ_health_url,
              company_employ_name: item.company_employ_name,
              cold_chain_personnel:item.cold_chain_personnel,
              mobilephone:item.mobilephone,
            }));
          })
          bbb.forEach((item, index) => {
            if (item.accessory_url != "") {
              if (item.accessory_type==="营业执照") {
                this.fileList = [{ name: item.accessory_type, url: item.accessory_url ? ( item.accessory_url) : this.morenUrl }]; this.uploadDisabled = true
                this.businessLicense = item.accessory_url;
              }
              if (item.accessory_type==='食品经营许可证') {
                this.fileList1 = [{ name: item.accessory_type, url: item.accessory_url ? (item.accessory_url) : this.morenUrl }]; this.uploadDisabled1 = true
                this.license = item.accessory_url;
              }
              if (item.accessory_type==='法人身份证正面') {
                this.fileList2 = [{ name: item.accessory_type, url: item.accessory_url ? ( item.accessory_url) : this.morenUrl }]; this.uploadDisabled2 = true
                this.id_card = item.accessory_url;
              }
              if (item.accessory_type==='法人身份证背面') {
                this.fileList3 = [{ name: item.accessory_type, url: item.accessory_url ? ( item.accessory_url) : this.morenUrl }]; this.uploadDisabled3 = true
                this.id_card_side = item.accessory_url;
              }
            }
              switch (item.accessory_type) {
                  case "营业执照":that.form4.accessory_list[0]={
                      accessory_type: item.accessory_type,
                      accessory_url: item.accessory_url,
                  };break;
                  case "食品经营许可证":that.form4.accessory_list[1]={
                      accessory_type: item.accessory_type,
                      accessory_url: item.accessory_url,
                  };break;
                  case "法人身份证正面":that.form4.accessory_list[2]={
                      accessory_type: item.accessory_type,
                      accessory_url: item.accessory_url,
                  };break;
                  case "法人身份证背面":that.form4.accessory_list[3]={
                      accessory_type: item.accessory_type,
                      accessory_url: item.accessory_url,
                  };break;
              }
          })
          this.form4.accessory_list[0].accessory_url =  this.businessLicense;
          this.form4.accessory_list[1].accessory_url = this.license;
          this.form4.accessory_list[2].accessory_url = this.id_card;
          this.form4.accessory_list[3].accessory_url = this.id_card_side;
          that.handleUserLevel(that.userData,that.form4);
        }
      })
    },
    getbizList () {
      var that = this;
      axios.get('/pc/company/list/state', { filing_state: '3', company_type_id: '15' }).then((v) => {
        this.bizList = v.data.company_list
      })
    },
    logTimeChange (val) { },
    handleChecked (index, val) {
      if (index == 1) {
        this.form4.main_subject_ids_list = [];
        this.Options1.forEach(item => {
          val.forEach(item1 => {
            if (item.dictionary_field == item1) {
              this.form4.main_subject_ids_list.push(item.dictionary_id)
            }
          })
        })
      } else if (index == 2) {
        this.form4.sub_subject_ids_list = [];
        this.Options2.forEach(item => {
          val.forEach(item1 => {
            if (item.dictionary_field == item1) {
              this.form4.sub_subject_ids_list.push(item.dictionary_id)
            }
          })
        })
      } else if (index == 3) {
        this.form4.business_ids_list = [];
        this.Options3.forEach(item => {
          val.forEach(item1 => {
            if (item.dictionary_field == item1) {
              this.form4.business_ids_list.push(item.dictionary_id)
            }
          })
        })
      }

    },
    getType () {
      var that = this;
      axios.get('/pc/company-type/all').then((v) => {
        that.typetOptions = v.data.company_type_list;
      })
    },
    getTag (params) {
      var that = this;
      axios.get('/pc/company-tag/all', { company_type_id: params }).then((v) => {
        that.tagOptions = v.data.company_tag_list;
      })
    },
    getDictionary (params) {
      var that = this;
      axios.get('/pc/dictionary/all', { company_tag_id: params }).then((v) => {
        that.Options1 = v.data.dictionary_list.经营信息.主营项目;
        that.Options2 = v.data.dictionary_list.经营信息.兼营项目;
        that.Options3 = v.data.dictionary_list.经营信息.经营范围;
        that.Options4 = v.data.dictionary_list.经营信息.储存条件;
        that.Options5 = v.data.dictionary_list.经营信息.商品类型;
      })
    },
    beforeAvatarUpload (file) {
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      return isLt10M;
    },
    handlepoll11 (response, file, fileList) {
      this.uploadDisabled11 = true;
      if(this.imageUrl=='1234567'){
        this.form.List[0].healthimg = response.data.visit_url
      }else{
        tengxuncloud(response).then((res) => {
          this.form.List[0].healthimg = res.url
       })
      }
    },
    handlepoll (response, file, fileList) {
      this.uploadDisabled = true;
      if(this.imageUrl=='1234567'){
        this.$set(this.form4.accessory_list, 0, { accessory_type: '营业执照', accessory_url: response.data.visit_url });
      }else{
        tengxuncloud(response).then((res) => {
          this.$set(this.form4.accessory_list, 0, { accessory_type: '营业执照', accessory_url: res.url });
       })
      }

      // axios.get('/pc/baidu/orc/discern', { file_path: response.data.visit_url, discern_type: '2', id_card_side: '' }).then(v => {
      //   if (JSON.stringify(v.data.discern_result) != '{}') {
      //     this.form4.record_code = v.data.discern_result.record_code;
      //     this.form4.credit_code = v.data.discern_result.credit_code;
      //     this.form4.company_name = v.data.discern_result.company_name;
      //     this.form4.unuseful_time = v.data.discern_result.unuseful_time;
      //     this.form4.record_time = v.data.discern_result.record_time;
      //     this.form4.legal_person_name = v.data.discern_result.operator;
      //   }

      // })
    },
    handlepoll1 (response, file, fileList) {
      this.uploadDisabled1 = true;
      if (this.form4.company_tag_id == 2) {
        if(this.imageUrl=='1234567'){
         this.$set(this.form4.accessory_list, 1, { accessory_type: '备案证', accessory_url: response.data.visit_url});
      }else{
        tengxuncloud(response).then((res) => {
          this.$set(this.form4.accessory_list, 1, { accessory_type: '备案证', accessory_url: res.url });
       })
       }

      } else {
         if(this.imageUrl=='1234567'){
        this.$set(this.form4.accessory_list, 1, { accessory_type: '食品经营许可证', accessory_url: response.data.visit_url});
      }else{
        tengxuncloud(response).then((res) => {
          this.$set(this.form4.accessory_list, 1, { accessory_type: '食品经营许可证', accessory_url: res.url });
       })
       }

      }


    },
    handlepoll2 (response, file, fileList) {
      this.uploadDisabled2 = true;
      if(this.imageUrl=='1234567'){
        this.$set(this.form4.accessory_list, 2, { accessory_type: '法人身份证', accessory_url: response.data.visit_url});
        this.getcart1(response.data.visit_url)
      }else{
        tengxuncloud(response).then((res) => {
          this.$set(this.form4.accessory_list,2, { accessory_type: '法人身份证', accessory_url: res.url });
         this.getcart1(res.url)
      })
      }
    },
    getcart1(params){
       axios.get('/pc/baidu/orc/discern', { file_path: params, discern_type: '1', id_card_side: 'front' }).then(v => {
        if (JSON.stringify(v.data.discern_result) != '{}') {
          this.form4.user_province= v.data.discern_result.province;
          this.form4.user_city= v.data.discern_result.city;
          this.form4.user_area= v.data.discern_result.area;
          this.form4.user_town= v.data.discern_result.town;
          this.form4.user_vill= v.data.discern_result.vill;
          this.form4.user_sex=(v.data.discern_result.user_sex=='男'?'1':'2');
           this.form4.legal_person_name=v.data.discern_result.user_name;
           this.form4.user_address=v.data.discern_result.user_address
          this.form4.user_nation= v.data.discern_result.user_nation;
          this.form4.user_born= v.data.discern_result.user_born;
           this.handleUserLevel(this.userData, this.form4);
        }else{
           this.$message({
            message: '请上传对应的身份证附件',
            type: 'warning',
            duration: 2 * 1000
        });
        }

      })
    },
     handlepoll3 (response, file, fileList) {
      this.uploadDisabled3 = true;
      if(this.imageUrl=='1234567'){
       this.$set(this.form4.accessory_list, 3, { accessory_type: '身份证国徽', accessory_url: response.data.visit_url });
       this.getCart2(response.data.visit_url)
      }else{
        tengxuncloud(response).then((res) => {
          this.$set(this.form4.accessory_list, 3, { accessory_type: '身份证国徽', accessory_url: res.url });
          this.getCart2(res.url)
       })
      }
    },
    getCart2(params){
       axios.get('/pc/baidu/orc/discern', { file_path: params, discern_type: '1', id_card_side: 'back' }).then(v => {
        if (JSON.stringify(v.data.discern_result) != '{}') {
          this.form4.user_idcard_government = v.data.discern_result.user_idcard_government;
          this.form4.user_idcard_expirationtime = v.data.discern_result.user_idcard_expirationtime;
        }else{
           this.$message({
            message: '请上传对应的身份证附件',
            type: 'warning',
            duration: 2 * 1000
        });
        }

      })
    },
    handleRemove (file, fileList) {
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.uploadDisabled = false;
        this.$set(this.form4.accessory_list, 0, { accessory_type: '营业执照', accessory_url: '' });
      }).catch(() => {
      });

    },
    handleRemove1 (file, fileList) {
      this.uploadDisabled1 = false;
      if (this.form4.company_tag_id == 2) {
        this.$set(this.form4.accessory_list, 1, { accessory_type: '备案证', accessory_url: '' });
      } else {
        this.$set(this.form4.accessory_list, 1, { accessory_type: '食品经营许可证', accessory_url: '' });
      }

    },
    handleRemove2 (file, fileList) {
      this.uploadDisabled2 = false;
      this.$set(this.form4.accessory_list, 2, { accessory_type: '法人身份证', accessory_url: '' });

    },
     handleRemove3 (file, fileList) {
      this.uploadDisabled3 = false;
      this.$set(this.form4.accessory_list, 2, { accessory_type: '身份证国徽', accessory_url: '' });
    },
    beforeRemove (file, fileList) {
      if (file && file.status==="success") {
      return this.$confirm(`确定移除 ${file.name}吗？`);
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove11 (file, fileList) {
      let tmpname = this.form.List[0].name
      let tmptime = this.form.List[0].time
      this.form.List = [{ name: tmpname,mobilephone:this.form.List[0].mobilephone, healthimg: '', time: tmptime }]
      //this.form.List[0].healthimg=''
      this.uploadDisabled11 = false;
    },
    cancle () { this.form.List = [{ name: '',mobilephone:'', healthimg: '', time: '' }] },
    savepeople (form) {
      this.$refs.form.validate(variable => {
        if (variable) {
           this.form4.company_employ_list.push(objKeySort({
              employ_health_url: this.form.List[0].healthimg.substring(this.form.List[0].healthimg.indexOf("resources")),
              company_employ_name: this.form.List[0].name,
              employ_health_datedue: this.form.List[0].time,
              cold_chain_personnel:this.form.List[0].state,
              mobilephone:this.form.List[0].mobilephone
            }
            ));
            this.form.List = [{ name: '',mobilephone:'', healthimg: '', time: '',state:'' }]
            this.handleRemove11()
            this.$refs.upload.clearFiles();
        } else { return }
      })
    },
    handleDelete (index) { //删除行数
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.form4.company_employ_list.splice(index, 1)
      }).catch(() => {
      });

    },
    submitForm3 (form4) {
      this.form4.company_name=this.company_name;
      this.$refs[form4].validate((valid) => {
        if (valid) {
          if (this.form4.accessory_list[2].accessory_url == '') {
            this.$message.error('请上传身份证件照片');
            return false
          }
          if (this.filing_state == 1) {
            axios.post('/pc/company-circulate/save', this.form4).then((v) => {
              local.del('companyDetail');
              this.$message({
               message: '当前登记申请成功，正在等待管理员审核',
               type: 'success'
              });
              window.location.reload()
            }).catch(() => { })
          } else {
            Vue.delete(this.form4, 'produce_form_ids');
            Vue.delete(this.form4, 'produce_form_names');
            Vue.delete(this.form4, 'produce_type_ids');
            Vue.delete(this.form4, 'produce_type_names');
            this.form4.company_produce_id = String(this.form4.company_produce_id);
            axios.put('/pc/company-circulate/update', this.form4).then((v) => {
              local.del('companyDetail');
              this.$message({
               message: '当前登记申请成功，正在等待管理员审核',
               type: 'success'
              });
              window.location.reload()
            }).catch(() => { })
          }

        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
      })
    },
    save () {
      local.set('companyDetail', this.form4);
      this.$message({
        message: '保存草稿成功',
        type: 'success'
      });
    },
     async handleUserLevel(userData, user) {
      if (parseInt(userData.user_type) > 0 || user.user_province) {
        this.provinceOptions = await this.fetchRegionData(1);
        let province = this.provinceOptions.find(
          item =>item.region_name ==(user.user_province ? user.user_province :'')
        );
        this.provinceTemp = province;
        if (parseInt(userData.user_type) >=1 || user.user_city) {
          this.cityOptions = await this.fetchRegionData( 2, province.region_code );
          let city = this.cityOptions.find(
            item => item.region_name == ( user.user_city ?  user.user_city:'')
          );
          if (parseInt(userData.user_type) >= 2 || user.user_area) {
            this.areaOptions = await this.fetchRegionData(3, city.region_code);
            let area = this.areaOptions.find(
              item => item.region_name == (user.user_area ? user.user_area :'' )
            );
            this.cityTemp = city;
            if (parseInt(userData.user_type) >= 3 || user.user_town) {
              this.townOptions = await this.fetchRegionData( 4, area.region_code );
              let town = this.townOptions.find(
                item => item.region_name == (user.user_town ? user.user_town:'' )
              );
              this.areaTemp = area;
              if (parseInt(userData.user_type) >= 4 || user.user_vill) {
                this.villOptions = await this.fetchRegionData( 5, town.region_code );
                let vill = this.villOptions.find(
                  item => item.region_name == (user.user_vill ? user.user_vill:'')
                );
                this.townTemp = town;
                if (parseInt(userData.user_type) >= 5 || user.user_vill) {
                  this.villTemp = vill;
                  }
              }
            }
          }
        }
      }
    },
  fetchRegionData (type, region_high_code) {
    let region_type = type.toString();
    return new Promise((resolve, reject) => {
      axios
        .get("/pc/region/all", { region_type, region_high_code })
        .then(response => {
          resolve(response.data.region_list);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async handleChange (item, type) {
    // let regionData = await this.fetchRegionData(type, item.region_code);
    let regionData;
    if (item.region_code == undefined) {
      regionData = ''
    } else {
      regionData = await this.fetchRegionData(type, item.region_code);
    }
    switch (type) {
      case 2:
        this.cityOptions = regionData;
        this.form.province = item.region_name;
        this.areaOptions = [];
        this.townOptions = [];
        this.villOptions = [];
        this.cityTemp = "";
        this.areaTemp = "";
        this.townTemp = "";
        this.villTemp = "";
        break;
      case 3:
        this.areaOptions = regionData;
        this.form.city = item.region_name;
        this.townOptions = [];
        this.villOptions = [];
        this.areaTemp = "";
        this.townTemp = "";
        this.villTemp = "";
        break;
      case 4:
        this.townOptions = regionData;
        this.form.area = item.region_name;
        this.villOptions = [];
        this.townTemp = "";
        this.villTemp = "";
        break;
      case 5:
        this.villOptions = regionData;
        this.form.town = item.region_name;
        this.villTemp = "";
        break;
      case 6:
        this.form.vill = item.region_name;
        break;
    }
  },
  }
}
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #409eff;
    border-color: #409eff;
    color: #ffffff;
  }
  .type_img {
    position: absolute;
    right: 10%;
    width: 80px;
    z-index: 100;
    top: 15%;
  }
  .back_btn {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(217, 222, 225, 1);
  }
  .recordadd-title {
    height: 34px;
    line-height: 34px;
    border-left: 2px solid #788287;
  }
  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/ .el-checkbox-button__inner {
    border-left: 1px solid #dcdfe6;
    border-radius: 4px !important;
  }
  /deep/ .el-table.no-header .el-table__header {
    display: none;
  }
  .people-message .el-form-item {
    margin-bottom: 0px;
  }
  /deep/ .el-col-7 .el-form-item__content {
    width: 160px !important;
  }
  .recordadd-upload {
    width: 160px;
    height: 160px;
    float: left;
    margin-right: 10px;
    .disabled {
      height: 100%;
    }
    /deep/ .el-upload {
      width: 160px;
      height: 160px;
      line-height: 160px;
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 160px;
      height: 160px;
    }
  }
  .health_url {
    /deep/ .el-upload--picture-card {
      width: 70px;
      height: 40px;
      background-color: transparent;
      border: none;
      .el-button--medium {
        position: absolute;
        left: 10px;
      }
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 70px;
      height: 40px;
    }
  }
  table .cell .is-error {
    padding-bottom: 20px;
  }
  .el-table th.is-leaf,
  .el-table td {
    border-bottom: none;
  }
  /deep/ .disabled .el-upload--picture-card {
    display: none !important;
  }
  /deep/ .el-table {
    .el-table__body,
    .el-table__header {
      width: 100% !important;
    }
  }
  /deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: #409eff;
    border-color: #409eff;
    color: #fff;
  }
  .el-checkbox-button {
    margin-bottom: 10px;
  }
}
</style>
